.marker-hitbox {
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: 0.1s ease 0s normal forwards 1 fadein;
}

.marker-hitbox:hover {
    cursor: pointer;
}

.marker-name {
    position: absolute;
    bottom: 50px;
    color: black;
    padding: 2px 7px;
    background: white;
    border-radius: 5px;
    opacity: 0;
    transition: all 250ms ease-out;
}

.marker-hitbox:hover > .marker-name {
    bottom: 60px;
    opacity: 1;
}

.marker {
    background-color: #86bbf88f;
    border: 2px solid white;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    transition: all 150ms ease-in;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.marker-hitbox:hover > .marker {
    border: 0px solid white;
    height: 25px;
    width: 25px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@keyframes fadein {
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
}

@-webkit-keyframes fadein {
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
} 