.container {
    padding: 10px 20px;
    min-width: 180px;
}

.icon {
    display: flex;
    margin: auto 0;
}

.text {
    margin-top: 0 !important;
}